.montserrat-light {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .montserrat-medium {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .montserrat-semibold {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  * {
    font-family: "Montserrat", sans-serif;
  }
  